import React, {Component} from 'react'
import CprNummerComponent from 'src/pages/CprNummerComponent.jsx'
import {formatTimestamp, naIfEmpty} from 'src/util/util.js'
import Check from "src/util/Check.jsx";
import BeskyttetDataComponent from "src/pages//BeskyttetDataComponent.jsx";

function format(pre, post, divider) {
  if (!pre && !post)
    return "";
  if (!pre)
    return post;
  if (!post)
    return pre;
  return pre + divider + post;
}

class AktoerView extends Component {
  render() {
    const {aktoer, erSupport} = this.props;
    const aktoerBy = format(aktoer.postnr, aktoer.postby, " ");
    const aktoerLand = format(aktoer.landekode,aktoer.land, " - ");
    const aktoerKommune = format(aktoer.kommunekode, aktoer.kommune, " - ");

    return (
        <div className="stil-col">
          <div className="line">
            <div className="label">CPR nummer</div>
            <div className="field">
              <CprNummerComponent erSupport={erSupport} cprNr={aktoer.cpr_nr} />
            </div>
          </div>
          <div className="line"><div className="label">UNI-ID</div><div className="field">{aktoer.uni_id}</div></div>
          <div className="line"><div className="label">Adresse</div><div className="field"><BeskyttetDataComponent erSupport={erSupport} data={aktoer.vej} erBeskyttet={aktoer.beskyttet}/></div></div>
          <div className="line"><div className="label">By</div><div className="field"><BeskyttetDataComponent erSupport={erSupport} data={aktoerBy} erBeskyttet={aktoer.beskyttet} /></div></div>
          <div className="line"><div className="label">Land</div><div className="field"><BeskyttetDataComponent erSupport={erSupport} data={aktoerLand} erBeskyttet={aktoer.beskyttet}/></div></div>
          <div className="line"><div className="label">Kommune</div><div className="field"><BeskyttetDataComponent erSupport={erSupport} data={aktoerKommune} erBeskyttet={aktoer.beskyttet} /></div></div>
          <div className="line"><div className="label">Beskyttet</div><div className="field"><Check boo={aktoer.beskyttet}/></div></div>
          <div className="line"><div className="label">Alias</div><div className="field">{aktoer.alias_fornavn}&nbsp;{aktoer.alias_efternavn}</div></div>
          <div className="line"><div className="label">Oprettet</div><div className="field">{formatTimestamp(aktoer.oprettet_ts)}</div></div>
          <div className="line"><div className="label">Sidst ændret</div><div className="field">{formatTimestamp(aktoer.aendret_ts)}</div></div>
          <div className="line"><div className="label">Sidste importkørsel</div><div className="field">{naIfEmpty(formatTimestamp(aktoer.import_kilde_timestamp))}</div></div>
          <div className="line"><div className="label">Sidste import kilde</div><div className="field">{naIfEmpty(format(aktoer.import_kilde_navn))}</div></div>
        </div>
    );
  }
}

export default AktoerView;