import React, {Component} from "react";
import AktoerView from "src/pages/AktoerView.jsx";
import GrupperView from "src/pages/GrupperView.jsx";
import CloseButton from "src/util/CloseButton.jsx";
import BeskyttetDataComponent from "src/pages/BeskyttetDataComponent.jsx";

class EksternModal extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {visModal, lukModal, ekstern, erSupport} = this.props;
        let eksternNavn;

        if (!visModal)
            return null;

        if (visModal) {
            eksternNavn = ekstern.fornavn + " " + ekstern.efternavn;
        }

        return (
            <div>
                <div className="fds-modal" id="ekstern-modal" aria-hidden={!visModal} role="dialog"
                     aria-modal="true" aria-labelledby="modal-example-title">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-example-title">Ekstern: <BeskyttetDataComponent erSupport={erSupport} data={eksternNavn} erBeskyttet={ekstern.beskyttet}
                                                                                                                  erOverskrift={true}/></h2>
                            <CloseButton lukModal={lukModal}/>
                        </div>
                        <div className="modal-body">
                            <div className="modalcontent">
                                <div className="stil-col">
                                    <AktoerView aktoer={ekstern} erSupport={erSupport}/>
                                    <div className="line">
                                        <div className="label">Rolle</div>
                                        <div className="field">{ekstern.rolle}</div>
                                    </div>
                                    <GrupperView grupper={ekstern.grupper}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="buttongroup">
                                <button id="ekstern-close-button" className="margleft10 stil-primary-button button" onClick={lukModal}
                                        title="Lukker dialogen">
                                    Luk
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {visModal && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}

export default EksternModal

