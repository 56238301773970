import {connect} from 'react-redux'
import * as actions from "src/actions/soegning.js";
import * as loginActions from "src/actions/login.js";
import BrugerSupportSoegningMain from "src/containers/BrugerSupportSoegningMain.jsx";
import * as afidentificerActions from "src/actions/afidentificer.js";

const mapStateToProps = (state) => {
    return {
        soegning: state.soegning,
        login: state.login
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        supportadgang: (instRef) => dispatch(loginActions.supportadgang(instRef)),
        brugerSoegningSupport: (soegestreng, sortfelt, sortdirection, side) => dispatch(actions.brugerSoegningSupport(soegestreng, sortfelt, sortdirection, side)),
        hentElev: (aktoerUuid) => dispatch(actions.hentElev(aktoerUuid)),
        afidentificerPerson: (personUuid) => dispatch(afidentificerActions.afidentificerPerson(personUuid)),
        hentMedarbejder: (aktoerUuid) => dispatch(actions.hentMedarbejder(aktoerUuid)),
        hentEkstern: (aktoerUuid) => dispatch(actions.hentEkstern(aktoerUuid)),
        skjulAktoerModal: () => dispatch(actions.skjulAktoerModal())
    }
};

const BrugerSupportSoegningConnector = connect(mapStateToProps, mapDispatchToProps)(BrugerSupportSoegningMain);
export default BrugerSupportSoegningConnector;
