import React, {Component} from 'react'

class VersionMain extends Component {

  componentDidMount() {
    const {loadFrontendVersion, loadBackendVersion} = this.props;
    loadFrontendVersion();
    loadBackendVersion();
  }

  render() {
    const {frontendVersion, backendVersion} = this.props.version;

    return (
        <div>
          <h1>Brugeroversigt</h1>
          <ApplicationStatus
              frontendVersion={frontendVersion}
              backendVersion={backendVersion}
          />

          <Version version={frontendVersion} />
          <Version version={backendVersion} />
        </div>
    )
  }
}

function ApplicationStatus(props) {
  const versions = Object.values(props);

  if (versions.some(version => !version))
    return (
        <div className="alert alert-info">
          Kunne ikke hente versionsoplysninger for alle systemer.
        </div>
    );

  if (!versions.every(version => version.applicationVersion === versions[0].applicationVersion))
    return (
        <div className="alert alert-danger">
          Alle systemer i Brugeroversigt har ikke samme version.
        </div>
    );

  return (
      <div className="alert alert-success">
        Alle systemer i Brugeroversigt har samme version.
      </div>
  );
}

function Version(props) {
  const {version} = props;
  if (!version)
    return null;

  return (
      <div>
        <table className="version-table">
          <tr>
            <th className="version-table-title"><strong>{version.applicationName}</strong></th>
            <th></th>
          </tr>
          <tr>
            <td>Version:</td>
            <td>{version.applicationVersion}</td>
          </tr>
        </table>
      </div>
  )
}

export default VersionMain;
