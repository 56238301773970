import React from 'react';

const Footer = () => {
  const items = [{
    title: 'Styrelsen for It og Læring'
  }, {
    title: 'Support',
    url: 'https://jira.stil.dk/servicedesk/customer/portal/7/create/220'
  }, {
    title: 'Hjælp til brugeroversigten',
    url: 'https://viden.stil.dk/display/STILVIDENOFFENTLIG/Brugeroversigten'
  }, {
    title: 'Beskyttelse af personoplysninger',
    url: 'https://viden.stil.dk/display/OFFGDPR/Unilogin'
  }].map((item, i, items) => {
    const addBullet = i !== items.length - 1;
    return <span key={i}>{item.url != null ? <a href={item.url} title={item.title}>{item.title}</a> : <span className="footer-bold" title={item.title}>{item.title}</span>} <span className={addBullet ? "footer-bullet" : null}> {addBullet ? String.fromCharCode(183) : null} </span></span>
  });
  return <footer><div className="footer">{items}</div></footer>
};

export default Footer;