import React, {Component} from "react";
import AktoerView from "src/pages/AktoerView.jsx";
import GrupperView from "src/pages/GrupperView.jsx";
import CloseButton from "src/util/CloseButton.jsx";
import BeskyttetDataComponent from "src/pages/BeskyttetDataComponent.jsx";

class MedarbejderModal extends Component {

    constructor(props) {
        super(props);
    }


  render() {
    const {visModal, lukModal, medarbejder, erSupport} = this.props;
    let medarbejderNavn;

    if (!visModal)
        return null;

    if(visModal){
        medarbejderNavn = medarbejder.fornavn + " " + medarbejder.efternavn;
    }

        return (
            <div>
                <div className="fds-modal" id="medarbejder-modal" aria-hidden={!visModal} role="dialog"
                     aria-modal="true" aria-labelledby="modal-example-title">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title" id="modal-example-title">Medarbejder:  <BeskyttetDataComponent erSupport={erSupport} data={medarbejderNavn} erBeskyttet={medarbejder.beskyttet} erOverskrift={true}/></h2>
                            <CloseButton lukModal={lukModal}/>
                        </div>
                        <div className="modal-body">
                            <div className="modalcontent">
                                <div className="stil-col">
                                    <AktoerView aktoer={medarbejder} erSupport={erSupport}/>
                                    <div className="line">
                                        <div className="label">Roller</div>
                                        <div className="field">{medarbejder.roller.join(", ")}</div>
                                    </div>
                                    <GrupperView grupper={medarbejder.grupper}/>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="buttongroup">
                                <button id="medarbejder-close-button" className="margleft10 stil-primary-button button"
                                        onClick={lukModal}
                                        title="Lukker dialogen">
                                    Luk
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {visModal && <div className="modal-backdrop fade show"></div>}
            </div>
        )
    }
}

export default MedarbejderModal

