import {connect} from 'react-redux'
import SoegeMain from "src/containers/SoegeMain.jsx";
import * as actions from "src/actions/soegning.js";

const mapStateToProps = (state) => {
  return {
    soegning: state.soegning,
    login: state.login
  };
};

const mapDispatchToProps  = (dispatch) => {
  return {
    soeg: (soegestreng,sortfelt,sortdirection,side) => dispatch(actions.soeg(soegestreng,sortfelt,sortdirection,side)),
    hentElev: (aktoerUuid) => dispatch(actions.hentElev(aktoerUuid)),
    hentMedarbejder: (aktoerUuid) => dispatch(actions.hentMedarbejder(aktoerUuid)),
    hentEkstern: (aktoerUuid) => dispatch(actions.hentEkstern(aktoerUuid)),
    skjulAktoerModal: () => dispatch(actions.skjulAktoerModal())
  }
};

const SoegeConnector = connect(
  mapStateToProps,
  mapDispatchToProps,
) (SoegeMain);

export default SoegeConnector;
